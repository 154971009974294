export default {
    daysCapacity(state) {
        return state.data;
    },
    isLoggedIn(state) {
        return state.user ? true : false;
    },
    returnMaxDays(state) {
        let days = [...state.settingsDetails.capacityByDay];
        let nights = [...state.settingsDetails.capacityByDay];
        let sortedNights = nights.sort(function(a, b) {
            return a.nightShift - b.nightShift;
        });
        let sortedDays = days.sort((a, b) => a.dayShifts - b.dayShifts);
        sortedNights.shift();
        sortedDays.shift();
        let topDays = sortedDays.slice(-5);
        let topNights = sortedNights.slice(-5);
        let worseDays = sortedDays.slice(0, 5);
        let worseNights = sortedNights.slice(0, 5);
        return {
            days: topDays.reverse(),
            nights: topNights.reverse(),
            worseDays: worseDays,
            worseNights: worseNights,
        };
    },
    returnPrevMonth(state) {
        return state.prevMonth.schedules;
    },
    getSettings(state) {
        return state.settings;
    },
    getDetails(state) {
        return state.settingsDetails;
    },
    getUsersList(state) {
        return state.usersInEdit;
    },
    getLimits(state) {
        return state.settingsDetails.activeLimits;
    },
    getUserFetch(state) {
        return state.userFetch;
    },
    getCalendar(state) {
        return state.calendarEvents;
    },
    getServices(state) {
        return state.service;
    },
    selected(state) {
        return state.selected;
    },
    getCapacity(state) {
        const details = state.settingsDetails;
        const settings = state.settings;
        const limits = details.activeLimits.data;
        const capacityByDay = details.capacityByDay.slice(1);
        let calMode = 0;
        if (state.calendarMode === "current") {
            calMode = -1;
        }
        if (state.calendarMode === "next") {
            calMode = 0;
        }
        if (state.calendarMode === "prev") {
            calMode = -2;
        }
        const capacityLimits = [];
        const days = ["Nd", "Pon", "Wt", "Śr", "Czw", "Pt", "Sob"];
        if (details.activeLimits) {
            for (let i = 0; i < capacityByDay.length; i++) {
                let singleLimit = limits.find(
                    (el) => el.dayNumber === capacityByDay[i].dayNumber
                );
                let dayIdx = new Date(
                    settings.adminYear,
                    settings.adminMonth + calMode,
                    capacityByDay[i].dayNumber
                ).getDay();
                capacityLimits.push({
                    ...capacityByDay[i],
                    defDay: singleLimit && singleLimit.day ?
                        singleLimit.day : settings.defaultLimit,
                    defNight: singleLimit && singleLimit.night ?
                        singleLimit.night : settings.defaultLimit,
                    dayName: days[dayIdx],
                });
            }
        } else {
            for (let i = 0; i < capacityByDay.length; i++) {
                let dayIdx = new Date(
                    settings.adminYear,
                    settings.adminMonth + calMode,
                    capacityByDay[i].dayNumber
                ).getDay();
                capacityLimits.push({
                    ...capacityByDay[i],
                    defDay: settings.defaultLimit,
                    defNight: settings.defaultLimit,
                    dayName: days[dayIdx],
                });
            }
        }

        return capacityLimits;
    },
    returnCalSettings(state) {
        const settings = state.settings;
        let mode = 0;
        if (state.calendarMode === "current") {
            mode = 0;
        }
        if (state.calendarMode === "next") {
            mode = 1;
        }
        if (state.calendarMode === "prev") {
            mode = -1;
        }
        if (state.calendarMode === "prev" && state.calPosition === 0) {
            mode = 0;
        }
        const daysInMonth = new Date(
            settings.adminYear,
            settings.adminMonth + mode,
            0
        ).getDate();
        const settingArray = [
            "index",
            {
                key: "firstName",
                label: "Imię",
                isRowHeader: true,
                thClass: "thsticky",
            },
            {
                key: "name",
                label: "Nazwisko",
                sortable: true,
                stickyColumn: true,
                isRowHeader: true,
                thClass: "thsticky",
            },

            {
                key: "phone",
                label: "Telefon",
                isRowHeader: true,
                thClass: "thsticky",
            },
            {
                key: "fuelCard",
                label: "KP",
                isRowHeader: true,
                thClass: "thsticky",
            },
            {
                key: "edit",
                label: "Edycja",
                isRowHeader: true,
                thClass: "thsticky",
            },
        ];
        for (let i = 1; i < daysInMonth + 1; i++) {
            settingArray.push({
                key: `day${i}`,
                sortable: true,
                tdClass: (value, key, item) => {
                    if (value === "Dzień") {
                        return "cell-day";
                    }
                    if (value === "Noc") {
                        return "cell-night";
                    }
                    if (value === "Wolne") {
                        return "cell-off";
                    }
                    if (value === "Noc rezerwa") {
                        return "cell-nightr";
                    }
                    if (value === "Do dyspozycji") {
                        return "cell-whocare";
                    } else {
                        return "none";
                    }
                },
                label: `${i}.${settings.adminMonth + mode}.${settings.adminYear}`,
                formatter: (value, key, item) => {
                    if (value === "Dzień") {
                        return "D";
                    }
                    if (value === "Noc") {
                        return "N";
                    }
                    if (value === "Wolne") {
                        return "W";
                    }
                    if (value === "Noc rezerwa") {
                        return "N R";
                    }
                    if (value === "Dzień rezerwa") {
                        return "D R";
                    }
                    if (value === "Do dyspozycji") {
                        return "DYS";
                    } else {
                        return value;
                    }
                },
            });
        }
        return settingArray;
    },
    returnCars(state) {
        const sorted = state.vehicles.sort((a, b) => a.reg.localeCompare(b.reg));
        return sorted;
    },
    returnWeek(state) {
        return state.settings.week;
    },
    getDriverList(state) {
        return state.allDrivers;
    },
    singleGrounded(state) {
        return state.grounded.single;
    },
    weekServices(state) {
        return state.weekServices;
    },
    returnCarDrivers(state) {
        return state.drivers;
    },
    returnAssignedDrivers(state) {
        const cars = state.vehicles;
        const driversList = state.assignedDrivers;
        const readyList = [];
        if (driversList && cars) {
            for (let single in cars) {
                let singleCarId = cars[single].id;
                let singleCarReg = cars[single].reg;
                let findDrivers = driversList.filter(
                    (el) => el.primaryCar === singleCarId
                );
                let drivers = findDrivers.length ? findDrivers : [];
                readyList.push({ reg: singleCarReg, drivers });
            }
            return readyList;
        }
        return null;
    },
    returnResults(state) {
        const dayList = [];
        const nightsTotalIncome = [];
        const boltTotalDay = [];
        const freeNowTotalDay = [];
        const uberTotalDay = [];
        const boltTotalNight = [];
        const freeNowTotalNight = [];
        const uberTotalNight = [];
        const daysTotalIncome = [];
        const dayTotalShifts = [];
        const nightTotalShifts = [];
        let totalKmD = null;
        let totalKmN = null;
        let totalDaysWithMileage = 0;
        let totalNightsWithMileage = 0;
        let totalFuelDay = 0;
        let totalFuelNight = 0;
        const results = state.fetchedResults;
        if (results && results.length) {
            for (let i = 0; i < results.length; i++) {
                let day = results[i].date;
                let lookForDay = dayList.find((el) => el === day);
                if (!lookForDay) {
                    dayList.push(day);
                }
            }
            // sum up days total
            for (let i = 0; i < dayList.length; i++) {
                let singleDay = dayList[i];
                let filteredDay = results.filter(
                    (el) => el.date === singleDay && el.shift === "dzień"
                );
                let filteredNight = results.filter(
                    (el) => el.date === singleDay && el.shift === "noc"
                );
                let totalDay = 0;
                let boltDay = 0;
                let freenowDay = 0;
                let uberDay = 0;
                let boltNight = 0;
                let freenowNight = 0;
                let uberNight = 0;
                let totalNight = 0;

                filteredDay.forEach((el) => {
                    if (el.km_start > 0 && el.km_end > 0) {
                        totalKmD += el.driven_mileage
                        totalDaysWithMileage++;
                    }
                    if (el.total_income) {
                        totalDay += el.total_income;
                    }
                    if (el.total_bolt) {
                        boltDay += el.total_bolt;
                    }
                    if (el.total_freenow) {
                        freenowDay += el.total_freenow;
                    }
                    if (el.total_uber) {
                        uberDay += el.total_uber;
                    }
                });
                filteredNight.forEach((el) => {
                    if (el.km_start > 0 && el.km_end > 0) {
                        totalKmN += el.driven_mileage
                        totalNightsWithMileage++
                    }
                    if (el.total_income) {
                        totalNight += el.total_income;
                    }
                    if (el.total_bolt) {
                        boltNight += el.total_bolt;
                    }
                    if (el.total_freenow) {
                        freenowNight += el.total_freenow;
                    }
                    if (el.total_uber) {
                        uberNight += el.total_uber;
                    }
                });
                // other
                daysTotalIncome[i] = parseFloat(totalDay.toFixed(2));
                nightsTotalIncome[i] = parseFloat(totalNight.toFixed(2));
                boltTotalDay[i] = parseFloat(boltDay.toFixed(2));
                freeNowTotalDay[i] = parseFloat(freenowDay.toFixed(2));
                uberTotalDay[i] = parseFloat(uberDay.toFixed(2));
                boltTotalNight[i] = parseFloat(boltNight.toFixed(2));
                freeNowTotalNight[i] = parseFloat(freenowNight.toFixed(2));
                uberTotalNight[i] = parseFloat(uberNight.toFixed(2));
                dayTotalShifts[i] = filteredDay.length;
                nightTotalShifts[i] = filteredNight.length;
            }
            return (
                state.fetchedResults && {
                    labels: dayList,
                    daysTotalIncome,
                    nightsTotalIncome,
                    boltTotalDay,
                    boltTotalNight,
                    uberTotalDay,
                    uberTotalNight,
                    freeNowTotalDay,
                    freeNowTotalNight,
                    dayTotalShifts,
                    nightTotalShifts,
                    totalKmD,
                    totalKmN,
                    totalDaysWithMileage,
                    totalNightsWithMileage
                }
            );
        }

    },
    returnAllResults(state) {
        return state.fetchedResults;
    },
    getListOfBonus(state){
        let returnList = []
        if(state.bonuses.length){
            const formatted = state.bonuses.forEach(el => {
                returnList.push({...el.attributes})
            })
            return returnList;
        } else {
            return []
        }

    },
    getFuelEmp(state){
        return state.fuelEmp 
    },
    getFuelWeek(state){
        return state.fuelCalendar;
    },
    returnSavedDayFuel(state){
        return state.savedFuel;
    },
    getCurrentUser(state){
        return state.user
    },
    getOtherResults(state){
        return state.otherResults
    },
    getDashboardResult(state){
        return state.dashboardResults
    },
    getDashboardWeeklyResult(state){
        return state.dashboardWeeklyResults
    },
    getRentals(state){
        return state.rentals
    },
    getDailyResults(state){
        return state.dailyResults
    },
    returnDoorEvents(state){
        const doorMapped = state.doorEvents.map(el => {
            return {
                ...el,
                dayFormat: new Date(`${el.date} ${el.time}`)
            }
        })
        doorMapped.sort((a,b) => a.dayFormat - b.dayFormat)
        return doorMapped
    }
};