var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tab"},[(!this.isLoading)?_c('div',[_c('div',{staticClass:"dashboard-menu"},[_c('div',{staticClass:"dashboard-menu-item",class:[_vm.currentMenu === 1 ? 'active' : ''],on:{"click":function($event){_vm.currentMenu = 1}}},[_vm._v(" Panel główny ")])]),(_vm.currentMenu === 1)?_c('div',[_c('b-row',{staticClass:"main-settings",attrs:{"align-v":"stretch"}},[_c('b-col',[_c('info-bar',{attrs:{"title":"Wybrany tydzień","content":`${_vm.returnWeekText}`,"icon":"calendar","variant":"secondary"}})],1),_c('b-col',[_c('info-bar',{attrs:{"title":"Ilość dostępnych zmian","variant":"secondary","icon":"puzzle","content":_vm.leftShifts}})],1)],1),_c('b-row',{staticClass:"align-items-center info-section"},[_c('b-col',[_c('info-bar',{attrs:{"title":"Wypełnienie grafiku","variant":"warning","icon":"square-half","content":`${
              _vm.getAverageShifts.perc > 0
                ? _vm.getAverageShifts.perc + '%'
                : 'Wygeneruj grafik'
            }`}})],1),_c('b-col',[_c('info-bar',{attrs:{"title":"Wypełnienie zmian","variant":"secondary","icon":"layers-half","content":`${_vm.returnCapacity.takenShifts}/${_vm.returnCapacity.availableShifts} `}})],1),_c('b-col',[_c('info-bar',{attrs:{"title":"Przesłane grafiki","variant":"secondary","icon":"people","content":_vm.returnDetails.amount > 0
                ? _vm.returnDetails.amount
                : 'Brak przesłanych grafików'}})],1)],1),_c('b-row',{staticClass:"align-items-center info-section"},[_c('b-col',[_c('info-bar',{attrs:{"title":"Potrzebne osoby do grafiku","variant":"warning","icon":"exclamation-circle","content":_vm.getAverageShifts.avgLeft}})],1),_c('b-col',[_c('info-bar',{attrs:{"title":"Średnia zmian / osoba","variant":"secondary","icon":"bar-chart-fill","content":`${_vm.getAverageShifts.avgPerPerson} `}})],1),_c('b-col',[_c('info-bar',{attrs:{"title":"Aktywni kierowcy","icon":"pencil","content":`${_vm.returnDrivers.length}`}})],1)],1),(_vm.getTopDays)?_c('b-row',{staticClass:"shift-checker"},[_c('b-col',[_c('info-bar',{attrs:{"title":"Ostatnio przesłane","content":_vm.returnDetails.schedules.slice(-3),"icon":"calendar","variant":"secondary","mode":"lastPersons"}})],1)],1):_vm._e(),(_vm.getTopDays)?_c('b-row',{staticClass:"shift-checker"},[_c('b-col',[_c('info-bar',{attrs:{"title":"Braki dyspozycji","content":_vm.returnMissing,"icon":"calendar","variant":"secondary","mode":"missing"}})],1)],1):_vm._e(),(_vm.returnDoorEvents)?_c('b-row',{staticClass:"shift-checker"},[_c('b-col',[_c('info-bar',{attrs:{"title":"Najbliższe wyłączenia","content":_vm.returnDoorEvents,"icon":"calendar","variant":"warning","mode":"doorHandler"}})],1)],1):_vm._e()],1):_vm._e()]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }