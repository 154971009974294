a<template>
  <b-tabs id="tab-container" pills content-class="mt-3" align="center">
    <b-tab title="Wysyłanie wiadomości SMS">
      <b-row class="align-items-center input-row">
        <b-col md="4">
          <h5>Wyślij SMS</h5>
          <p class="title">
            Wyślij natychmiast sms do wszystkich <b>aktywnych</b> kierowców.
          </p>
        </b-col>
        <b-col class="input-container">
          <b-form-textarea
            id="textarea"
            v-model="textMessage"
            placeholder="Wprowadź tekst wiadomości..."
            rows="6"
          ></b-form-textarea>
          <b-button variant="primary" class="float-right mt-3" @click="sendSms" :disabled="sendDisabled">Wyślij</b-button>
        </b-col>
      </b-row>
    </b-tab>
  </b-tabs>
</template>
<script>
export default {
  data() {
    return {
      textMessage: null,
      isLoading: false,
    };
  },
  created() {},
  methods: {
    async sendSms(){
        this.isLoading = true;
        await this.$store.dispatch("sendCustomSMStoAll", this.textMessage);
        this.isLoading = false;
    }
  },
  computed: {
    sendDisabled(){
        console.log(this.textMessage)
        return this.textMessage && this.textMessage.length && !this.isLoading ? false : true;
    }
  },
};
</script>

<style scoped>
#tab-container {
  width: 80%;
  background-color: #fff;
  border-radius: 20px;
  padding: 20px;
}
#tab-container .nav.nav-pills .nav-item a {
  color: black !important;
}
.limits-enter-active,
.limits-leave-active {
  transition: all 1s;
}
.limits-enter,
.limits-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.title {
  font-weight: 300;
}
.form-input {
  width: 100%;
}
.input-row {
  margin-top: 2em;
}
.input-container {
  text-align: center;
}

.close {
  background-color: black;
  padding: 20px;
}
.delete {
  margin-left: 20px;
  font-size: 20px;
  cursor: pointer;
}
.badge {
  padding: 5px;
  margin: 0px 5px;
}
.form-input {
  text-align: center;
}
@media (max-width: 500px) {
  .badges {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
}
</style>
