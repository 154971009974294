import store from "../../store/index";

// public pages
import login from "../pages/login.vue";
import notFound from "../pages/404.vue";
import calendar from "../components/Calendar.vue";
import PublicCalendar from "../pages/PublicCalendar.vue";
import PublicCalendarNew from "../pages/PublicCalendarNew.vue";
// admin pages
import admin from "../pages/admin.vue";
import adminDashboard from "../pages/Dashboard.vue";
import monthSettings from "../pages/MonthSettings.vue";
import monthPersonView from "../pages/MonthPersonView.vue";
import MonthCalendarNew from "../pages/WeekCalendar.vue";
import SingleScheduleView from "../pages/SingleScheduleView.vue";
import Vehicles from "../pages/Vehicles.vue";
import EditCar from "../components/vehicles/EditCar.vue";
import EditRental from "../components/vehicles/EditRental.vue";
import DriversList from "../pages/DriversList.vue";
import Generated from "../pages/Generated.vue";
import Raports from "../pages/Raports.vue";
import Results from "../pages/Results.vue";
import Bonus from "../pages/Bonus.vue";
import servicePage from "../pages/ServiceConfirmation.vue";
import Rental from "../pages/Rental.vue";
import DayResults from "../pages/DayResults.vue"
import WeekResults from "../pages/WeekResults.vue"
import Sms from "../pages/Sms.vue"

import Fuel from "../pages/Fuel.vue";

const routes = [
  {
    path: "/",
    component: calendar,
    children: [{ path: "", component: calendar, name: "calendar" }],
  },
  { path: "/login", component: login, name: "login" },
  {
    path: "/koordynator",
    component: PublicCalendar,
    name: "Kalendarz pracy koordynatora",
  },
  {
    path: "/kalendarz-pracy",
    component: PublicCalendarNew,
    name: "Kalendarz pracy pracownika",
  },
  {
    path: "/paliwo",
    component: Fuel,
    name: "System kontroli paliwa",
  },
  { path: "/ps/:id", component: servicePage, name: "service-check" },

  {
    path: "/admin",
    component: admin,
    children: [
      {
        path: "edycja-grafiku",
        component: MonthCalendarNew,
        name: "Edycja grafiku",
      },
      {
        path: "podglad-grafiku",
        component: monthPersonView,
        name: "Podglad grafiku",
      },
      {
        path: "grafiki-pracy",
        component: Generated,
        name: "Grafiki pracy",
      },
      {
        path: "raporty",
        component: Raports,
        name: "Raporty",
      },
      {
        path: "bonusy",
        component: Bonus,
        name: "Bonusy",
      },
      {
        path: "wyniki",
        component: Results,
        name: "Wyniki",
      },
      {
        path: "wyniki-dzienne",
        component: DayResults,
        name: "Wyniki dzienne",
      },
      {
        path: "wyniki-tygodniowe",
        component: WeekResults,
        name: "Wyniki tygodniowe",
      },
      {
        path: "wylacznosc",
        component: Rental,
        name: "Wylacznosc",
      },
      {
        path: "powiadomienia-sms",
        component: Sms,
        name: "Powiadomienia sms",
      },
      {
        path: "wylacznosc/:id",
        component: EditRental,
        name: "EditRental",
      },
      { path: "", component: adminDashboard, name: "AdminDashboard" },
      {
        path: "podglad-grafiku/:id",
        component: SingleScheduleView,
        name: "SingleSchedule",
      },
      { path: "miesiac", component: monthSettings, name: "Admin settings" },
      { path: "samochody", component: Vehicles, name: "Vehicles" },
      { path: "samochody/edycja/:id", component: EditCar, name: "edit-car" },
      {
        path: "pracownicy/lista-pracownikow",
        component: DriversList,
        name: "drivers-list",
      },
    ],
    async beforeEnter(to, from, next) {
      const isLoggedIn = store.getters["isLoggedIn"];
      const jwt = localStorage.getItem("identifier");

      if (
        isLoggedIn ||
        to.fullPath === "/paliwo" ||
        to.fullPath === "/login" ||
        to.fullPath === "/potwierdzenie-serwisu/"
      ) {
        next();
      } else {
        if (jwt) {
          const resposne = await store.dispatch("autoLogin");
          if(resposne){
            next();
          } else {
            localStorage.removeItem("identifier")
            next("/login")
          }
          next();
        } else {
          next("/login");
        }
      }
    },
  },
  { path: "*", component: notFound },
];

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default routes;
