<template>
  <div class="info-bar" :class="borderColor">
    <div class="w-100">
      <p class="title">{{title}}</p>
      <p class="number" v-if="content && mode !== 'lastPersons' && mode !== 'missing' && mode !== 'doorHandler'">{{content}}</p>
      <p class="number" v-if="content && mode === 'lastPersons'">        
        <ul class="last-sended">
          <li v-for="item in content" :key="item.id"> {{item.attributes.fullName}} - {{item.attributes.selectedDays.length}} zmian</li>
        </ul>
        </p>
      <p class="days" v-if="mode && mode === 'day'">
        <ul class="days-listing">
          <li v-for="item in topShifts.days" :key="item.dayNumber"> {{item.dayNumber}}.{{date.month}}.{{date.year}} : {{item.dayShifts}} zmian</li>
        </ul>
      </p>
      <p class="days" v-if="mode && mode === 'night'">
        <ul class="days-listing">
          <li v-for="item in topShifts.nights" :key="item.dayNumber"> {{item.dayNumber}}.{{date.month}}.{{date.year}} : {{item.nightShift}} zmian</li>
        </ul>
      </p>
      <p class="days" v-if="mode && mode === 'lDays'">
        <ul class="days-listing">
          <li v-for="item in topShifts.worseDays" :key="item.dayNumber"> {{item.dayNumber}}.{{date.month}}.{{date.year}} : {{item.nightShift}} zmian</li>
        </ul>
      </p>
      <p class="days" v-if="mode && mode === 'lNights'">
        <ul class="days-listing">
          <li v-for="item in topShifts.worseNights" :key="item.dayNumber"> {{item.dayNumber}}.{{date.month}}.{{date.year}} : {{item.nightShift}} zmian</li>
        </ul>
      </p>
     <div v-if="content && mode === 'missing'">
       <b-row>
         <b-col cols="6" v-for="item in content" :key="item.phone">
       <p class="number" >{{item.fullName}} / tel: {{item.phoneNumber}}</p>
         </b-col>
       </b-row>
     </div>
     <div v-if="content && mode === 'doorHandler'">
       <b-row>
         <b-col cols="12" v-for="item in content" :key="item.date + item.reg">
           <p class="number">{{item.date}} - {{item.time}}<br>{{item.reg}} | <small>{{item.type}}</small></p>
         </b-col>
       </b-row>
     </div>
    </div>
    <b-icon class="bar-icon" :icon="icon"/>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    icon: String,
    content: [String, Number, Array],
    topShifts: Object,
    currentShiftNumber: Number,
    variant: String,
    mode: String,
    date: Object,
  },
  computed: {
    borderColor() {
      if (this.variant === "primary" || !this.variant) {
        return "p1";
      }
      if (this.variant === "secondary") {
        return "p2";
      }
      if (this.variant === "warning") {
        return "p3";
      }
    },
  },
};
</script>

<style scoped>
ul {
  list-style: none;
  padding-left: 0px;
}
p {
  margin: 0;
}
.info-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  min-width: 390px;
  min-height: 130px;
  padding: 1.45em;
  border-radius: 10px;
  margin-bottom: 1em;
}
.title {
  color: #4e73df;
  text-transform: uppercase;
  font-size: 0.8em;
  margin-bottom: 10px;
  font-weight: 700;
}
.number {
  font-size: 1.4em;
  font-weight: 600;
}
.bar-icon {
  font-size: 1.9em;
  margin-left: 60px;
  opacity: 0.3;
}
.p1 {
  border-left: 0.25rem solid #4e73df;
}
.p2 {
  border-left: 0.25rem solid #f78812;
}
.p3 {
  border-left: 0.23rem solid #ff0000;
}
.p2 .title {
  color: #f78812;
}
.p3 .title {
  color: #ff0000;
}
.last-sended{
  font-size: 1em;
}
.days-listing {
  list-style-type: none;
}
@media (max-width: 690px) {
  .info-bar {
    width: 240px;
    min-width: 100%;
    flex-direction: column-reverse;
  }
  .bar-icon {
    margin-left: 0px;
    margin: 20px 0;
  }

  .number {
    text-align: center;
  }
}
</style>
