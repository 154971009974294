<template>
  <b-container>
    <div class="box">
      <div class="img-container">
        <img width="120%" src="https://api.mbtaxi.pl/uploads/mb_taxi_logo_68cc0680e8.png" />
      </div>
      <h2>Dziękujemy za potwierdzenie serwisu!</h2>
      Twój serwis został potwierdzony, prosimy o punktualny przyjazd we wskazane
      miejsce.
    </div>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      currentPageId: null,
    };
  },
  methods: {
    async confirmService() {
      const url = "/api/confirm-service";
      const uuid = this.currentPageId;
      console.log(uuid);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ uuid }),
      };
      try {
        const response = await fetch(url, requestOptions);
        const responseData = await response.json();
        console.log(response);
      } catch (error) {
        console.log(error);
      }
    },
  },
  computed: {
    // Using a computed property to get the current page ID from the route
    // You can customize this logic based on your route structure
    // Here, we're using the last segment of the route path as the page ID
    computedPageId() {
      const pathSegments = this.$route.path.split("/");
      return pathSegments[pathSegments.length - 1];
    },
  },
  watch: {
    // Watch for changes in the route to update the current page ID
    $route(to, from) {
      this.currentPageId = this.computedPageId;
    },
  },
  async mounted() {
    // Initialize the current page ID
    this.currentPageId = this.computedPageId;

    await this.confirmService();
  },
};
</script>

<style scoped>
.box {
  padding: 24px;
  text-align: center;
  border-radius: 16px;
  margin-top: 40px;
  background-color: black;
  color: #fff;
}
.img-container {
  width: 60%;
  border-radius: 16px;
  padding: 8px;
  margin: 16px auto;
}
</style>
