import { render, staticRenderFns } from "./WeekCalendar.vue?vue&type=template&id=16d72d1d&scoped=true&"
import script from "./WeekCalendar.vue?vue&type=script&lang=js&"
export * from "./WeekCalendar.vue?vue&type=script&lang=js&"
import style0 from "./WeekCalendar.vue?vue&type=style&index=0&id=16d72d1d&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16d72d1d",
  null
  
)

export default component.exports